import React, { useEffect, useState } from "react";
import { Plus, Eye, Edit } from "tabler-icons-react";
import { Select, ActionIcon, Badge, Text, Table, Modal, TextInput, Button } from "@mantine/core";
import { formatDate } from "../../utilities/utilities";
import GoogleEditor from "./GoogleEditor";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { registerEmailTemplate, getWordTemplateListAPI, updateEmailTemplate } from "../../utilities/apis/recovery";
import { min } from "date-fns";

const EmailList = ({ isViewOnly = false, useTemplateFunction }) => {
    const [uiConfig, setUiConfig] = useState({
        isModalOpen: false,
        isPreviewModalOpen: false,
        selectedRow: {},
        filter: {
            category: "All"
        },
        apiCall: true,
    });
    const [emailFormState, setEmailFormState] = useState({
        name: "",
        category: "",
        templateList: [
            {
                id: 1,
                name: "Test Template Email",
                category: "Warning",
                user: {
                    name: "Rana Pillai",
                },
                createdAt: new Date().toISOString(),
            }
        ],
        errors: {},
    });
    const [dropDown, setDropDown] = useState({
        category: ["All"],
    });
    const [googleEditor, setGoogleEditor] = useState({
        isViewOnly: isViewOnly,
        isModalOpen: false,
        isFullScreen: false,
        isApiCalled: false,
        body: null,
        subject: null,
        toEmail: [],
        ccEmail: [],
        bccEmail: [],
    });
    const validateForm = () => {
        const errors = {};
        if (!emailFormState.name) {
            errors.name = "Name is required";
        }
        if (!emailFormState.category) {
            errors.category = "Category is required";
        }
        setEmailFormState((prevState) => ({
            ...prevState,
            errors,
        }));
        return Object.keys(errors).length === 0;
    };
    const handleFormSubmit = async () => {
        if (validateForm()) {
            const payload = {
                name: emailFormState.name,
                category: emailFormState.category,
            };
            const response = await apiWrapWithErrorWithData(
                registerEmailTemplate(payload)
            );
            if (response.success) {
                setUiConfig((prevState) => ({
                    ...prevState,
                    isModalOpen: false,
                    apiCall: true,
                }));
            }
        }
    };
    const fetchWordTemplateList = async () => {
        const payload = {
            category: uiConfig.filter.category,
        };
        const response = await apiWrapWithErrorWithData(
            getWordTemplateListAPI(payload)
        );
        if (response.success) {
            setEmailFormState((prevState) => ({
                ...prevState,
                templateList: response.data.list,
            }));
            if (response.data.dropDown.category.length > 0) {
                setDropDown((prevState) => ({
                    ...prevState,
                    category: [...dropDown.category, ...response.data.dropDown.category],
                }));
            }
        }
    };
    const updateTemplate = async (body) => {
        const payload = {
            id: uiConfig.selectedRow.id,
            htmlContent: body
        };
        const response = await apiWrapWithErrorWithData(
            updateEmailTemplate(payload)
        );
        if (response.success) {
            setUiConfig((prevState) => ({
                ...prevState,
                isModalOpen: false,
                apiCall: true,
                selectedRow: null,
            }));
            setGoogleEditor((prevState) => ({
                ...prevState,
                isModalOpen: false,
                body: null,
            }));
        };
    };
    useEffect(() => {
        if (uiConfig.apiCall) {
            fetchWordTemplateList();
            setUiConfig((prevState) => ({
                ...prevState,
                apiCall: false,
            }));
        }
    }, [uiConfig.apiCall]);
    const handleUseTemplate = (body) => {
        const jsonTemplate = uiConfig.selectedRow;
        jsonTemplate.htmlContent = body;
        useTemplateFunction(jsonTemplate);
    };

    return (
        <>
            <Modal
                overflow="inside"
                title="add new template"
                size="calc(40vw)"
                opened={uiConfig.isModalOpen}
                onClose={() => {
                    setUiConfig((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                    }));
                    setEmailFormState((prevState) => ({
                        ...prevState,
                        name: "",
                        category: "",
                        errors: {},
                    }));
                }}
                closeOnClickOutside={false}
            >
                <TextInput
                    required
                    label="name"
                    type="text"
                    placeholder="enter template category"
                    value={emailFormState.name}
                    onChange={(event) => {
                        const newValue = event.currentTarget.value;
                        setEmailFormState((prevState) => ({
                            ...prevState,
                            name: newValue,
                        }));
                    }}
                    error={emailFormState.errors.name}
                />
                <Select
                    required
                    searchable
                    creatable
                    label="category"
                    placeholder="select category"
                    value={emailFormState.category}
                    data={[...new Set(dropDown.category)]}
                    getCreateLabel={(value) => `+ add ${value}`}
                    onCreate={(value) => {
                        setEmailFormState((prevState) => ({
                            ...prevState,
                            category: value,
                        }));
                        setDropDown((prevState) => ({
                            ...prevState,
                            category: [...dropDown.category, value],
                        }));
                    }}
                    onChange={(value) => {
                        setEmailFormState((prevState) => ({
                            ...prevState,
                            category: value,
                        }));
                    }}
                    error={emailFormState.errors.category}
                />
                <div className="flex justify-center mt-5">
                    <Button color="green" onClick={handleFormSubmit}>
                        save
                    </Button>
                </div>
            </Modal>
            <GoogleEditor
                formState={googleEditor}
                setFormState={setGoogleEditor}
                useTemplateFunction={isViewOnly ? handleUseTemplate : updateTemplate}
            />
            <div className="flex flex-col pt-8 pb-14 pl-6 pr-16">
                <div className="font-normal text-[20px] leading-[1.55] text-black" style={{ color: "#46BDE1" }} >
                    email templates
                </div>
                <div className="flex justify-start gap-5 my-4 items-end">
                    <Select
                        className="mt-4 w-80"
                        label="category"
                        value={uiConfig.filter.category}
                        data={[...new Set(dropDown.category)]}
                        onChange={(value) => {
                            setUiConfig((prevState) => ({
                                ...prevState,
                                filter: {
                                    ...prevState.filter,
                                    category: value,
                                },
                                apiCall: true,
                            }));
                        }}
                    />
                    {!isViewOnly && (
                        <ActionIcon
                            color="blue"
                            title="add new template"
                            className="mb-1"
                            onClick={() => setUiConfig({ ...uiConfig, isModalOpen: true })}
                        >
                            <Plus size={30} />
                        </ActionIcon>
                    )}
                </div>
                <div className="mt-10">
                    <Table striped highlightOnHover={true}>
                        <thead>
                            <tr>
                                <th>reference no.</th>
                                <th colSpan={3}>name</th>
                                <th colSpan={2}>category</th>
                                <th>created by</th>
                                <th>date</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailFormState.templateList.length === 0 ? (
                                <tr>
                                    <td colSpan={9} className="text-center">
                                        <Text size={22} className="m-8">
                                            No data found
                                        </Text>
                                    </td>
                                </tr>
                            ) : (
                                emailFormState.templateList.map((row) => (
                                    <tr key={row.id}>
                                        <td>{`template-${row.id}`}</td>
                                        <td colSpan={3}>{row.name}</td>
                                        <td colSpan={2}>
                                            <Badge>{row.category}</Badge>
                                        </td>
                                        <td>{row.user.name}</td>
                                        <td>{formatDate(row.createdAt)}</td>
                                        <td>
                                            {!isViewOnly ? (
                                                <ActionIcon
                                                    color="blue"
                                                    title="Edit"
                                                    onClick={() => {
                                                        setGoogleEditor({
                                                            ...googleEditor,
                                                            isModalOpen: true,
                                                            isFullScreen: false,
                                                            body: row.htmlContent,
                                                        });
                                                        setUiConfig({
                                                            ...uiConfig,
                                                            selectedRow: row,
                                                        });
                                                    }}
                                                >
                                                    <Edit />
                                                </ActionIcon>
                                            ) : (
                                                <ActionIcon
                                                    color="blue"
                                                    title="View"
                                                    onClick={() => {
                                                        setUiConfig({
                                                            ...uiConfig,
                                                            isPreviewModalOpen: true,
                                                            selectedRow: row,
                                                        })
                                                        setGoogleEditor({
                                                            ...googleEditor,
                                                            isModalOpen: true,
                                                            isFullScreen: false,
                                                            body: row.htmlContent,
                                                        });
                                                    }}
                                                >
                                                    <Eye />
                                                </ActionIcon>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default EmailList;
