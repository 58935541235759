import React, { useState } from "react";
import { Button, PasswordInput, Text } from "@mantine/core";
import { Lock } from "tabler-icons-react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { updatePasswordApi } from "../../utilities/apis/recovery";
import { showNotification } from "@mantine/notifications";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";
import { useNavigate } from "react-router-dom";

const UpdatePassword = () => {
    const navigate = useNavigate();
    const currentUser = useSelector(selectUserData);
    const [passwords, setPasswords] = useState({
        old: "",
        new: "",
    });
    function validate() {
        if (passwords.old === "") {
            showNotification({
                color: "red",
                message: "please fill old  password",
                title: "error",
            });
            return false;
        } else if (passwords.new === "") {
            showNotification({
                color: "red",
                message: "please fill new  password",
                title: "error",
            });
            return false;
        } else {
            return true;
        }
    }
    const handleUpdatePassword = async (e) => {
        if (validate()) {
            const response = await apiWrapWithErrorWithData(
                updatePasswordApi({
                    id: currentUser.id,
                    oldPassword: passwords.old,
                    newPassword: passwords.new,
                })
            );
            showNotification({
                title: "update password",
                color: response.success ? "green" : "red",
                message: response.message,
            });
        }
    };
    return (
        <div className=" bg-white">
            <div className="flex w-full h-[40rem] justify-center items-center">
                <div className="flex justify-center flex-col w-80">
                    <Text className="text-xl flex justify-center">update password</Text>
                    <PasswordInput
                        value={passwords.old}
                        onChange={(e) =>
                            setPasswords({ ...passwords, old: e.target.value })
                        }
                        className="my-2"
                        label="old password"
                        placeholder="enter old password"
                        icon={<Lock size={14} />}
                    />
                    <PasswordInput
                        value={passwords.new}
                        onChange={(e) =>
                            setPasswords({ ...passwords, new: e.target.value })
                        }
                        className="my-2"
                        label="new Password"
                        placeholder="Enter new password"
                        icon={<Lock size={14} />}
                    />
                    <Button
                        className="my-2"
                        color="green"
                        onClick={(e) => handleUpdatePassword(e)}
                    >
                        update password
                    </Button>
                    <Button className="my-2" onClick={(e) => navigate("/app")}>
                        back to home
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default UpdatePassword;
