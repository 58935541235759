import axios from "axios";
import {
  headersProvider
} from "../apiHelpers";
import constants from "../constants";

export const createRecovery = (recoveryData) =>
  axios.post(
    `${constants.utilityHost}/recovery/create`, {
      recoveryData,
    }, {
      headers: headersProvider(),
    }
  );

export const createDueDetail = (dueDetailData) =>
  axios.post(
    `${constants.utilityHost}/recovery/dueDetail/create`, {
      dueDetailData,
    }, {
      headers: headersProvider(),
    }
  );

export const createAction = (actionData) => {
  console.log("actionData from the API sending create", actionData);
  return axios.post(
    `${constants.utilityHost}/recovery/action/create`, {
      actionData,
    }, {
      headers: headersProvider(),
    }
  );
}
export const updateRecovery = (recoveryData) =>
  axios.put(
    `${constants.utilityHost}/recovery/update`, {
      recoveryData,
    }, {
      headers: headersProvider(),
    }
  );

export const updateDueDetail = (dueDetailData) =>
  axios.put(
    `${constants.utilityHost}/recovery/dueDetail/update`, {
      dueDetailData,
    }, {
      headers: headersProvider(),
    }
  );

export const updateAction = (actionData) => {
  console.log("actionData from the API sending update", actionData);
  return axios.put(
    `${constants.utilityHost}/recovery/action/update`, {
      actionData,
    }, {
      headers: headersProvider(),
    }
  );
}

export const getAllActions = (data) =>
  axios.post(
    `${constants.utilityHost}/recovery/action/getAll`, data, {
      headers: headersProvider(),
    }
  );

export const disableAction = ({
    id
  }) =>
  axios.post(`${constants.utilityHost}/recovery/action/disable`, {
    data: {
      id,
    },
  }, {
    headers: headersProvider(),
  });

export const fetchPartyDetailApi = ({
    name
  }) =>
  axios.post(
    `${constants.utilityHost}/recovery/party/list`, {
      name,
    }, {
      headers: headersProvider(),
    }
  );

export const actionDropDown = (dueDetailId) =>
  axios.post(`${constants.utilityHost}/recovery/action/dropdown`, dueDetailId, {
    headers: headersProvider(),
  });

export const fetchRecoveryDetailApi = ({
    recoveryData
  }) =>
  axios.post(
    `${constants.utilityHost}/recovery/list`, {
      recoveryData
    }, {
      headers: headersProvider(),
    }
  );

export const getSpecificRecoveryDetail = ({
    recoveryId
  }) =>
  axios.post(
    `${constants.utilityHost}/recovery/getSpecific`, {
      recoveryId
    }, {
      headers: headersProvider(),
    }
  );

export const disableRecovery = ({
  recoveryId
}) => axios.put(
  `${constants.utilityHost}/recovery/disable`, {
    recoveryId
  }, {
    headers: headersProvider(),
  }
);

export const getEmailTemplateList = ({
  recoveryId
}) => axios.post(
  `${constants.utilityHost}/recovery/emailTemplate/list`, {
    recoveryId
  }, {
    headers: headersProvider(),
  }
);

export const getNoticeTemplateList = ({
  recoveryId
}) => axios.post(
  `${constants.utilityHost}/recovery/noticeTemplate/list`, {
    recoveryId
  }, {
    headers: headersProvider(),
  }
);

export const pauseUnpauseAction = (data) => axios.post(`${constants.utilityHost}/recovery/action/pauseUnpause`, data, {
  headers: headersProvider(),
});

export const saveEmailTemplate1 = ({
  actionId,
  emailTemplateContent
}) => axios.post(
  `${constants.utilityHost}/recovery/emailTemplate/save`, {
    actionId,
    emailTemplateContent
  }, {
    headers: headersProvider(),
  }
);

export const saveNoticeTemplate = ({
  actionId,
  noticeTemplateContent
}) => axios.post(
  `${constants.utilityHost}/recovery/noticeTemplate/save`, {
    actionId,
    noticeTemplateContent
  }, {
    headers: headersProvider(),
  }
);

export const lockEmailTemplate = ({
  actionId,
  emailTemplateContent,
  emailTemplateHTML,
  isEmailLocked
}) => axios.post(
  `${constants.utilityHost}/recovery/emailTemplate/lock`, {
    actionId,
    emailTemplateContent,
    emailTemplateHTML,
    isEmailLocked
  }, {
    headers: headersProvider(),
  }
);

export const lockNoticeTemplate = ({
  actionId,
  noticeTemplateContent,
  isNoticeLocked
}) => axios.post(
  `${constants.utilityHost}/recovery/noticeTemplate/lock`, {
    actionId,
    noticeTemplateContent,
    isNoticeLocked
  }, {
    headers: headersProvider(),
  }
);

export const createInvoiceDataAPI = ({
  number,
  date,
  amount,
  currencyType,
  creditPeriod,
  files,
  penaltyRate,
  recoveryId
}) => axios.post(`${constants.utilityHost}/recovery/invoiceData/create`, {
  number,
  date,
  amount,
  currencyType,
  creditPeriod,
  files,
  penaltyRate,
  recoveryId
}, {
  headers: headersProvider(),
})

export const getInvoiceDataAPI = ({
  dueDetailId
}) => axios.post(`${constants.utilityHost}/recovery/invoiceData/get`, {
  dueDetailId
}, {
  headers: headersProvider(),
});

export const updateInvoiceDataAPI = ({
  id,
  number,
  date,
  amount,
  currencyType,
  creditPeriod,
  files,
  penaltyRate
}) => axios.post(`${constants.utilityHost}/recovery/invoiceData/update`, {
  id,
  number,
  date,
  amount,
  currencyType,
  creditPeriod,
  files,
  penaltyRate
}, {
  headers: headersProvider(),
});

export const disableInvoice = ({
  id,
  dueDetailId
}) => axios.post(`${constants.utilityHost}/recovery/invoiceData/disable`, {
  id,
  dueDetailId
}, {
  headers: headersProvider(),
});

export const executeSpecificAction = ({
  actionId
}) => axios.post(`${constants.schedularHost}/email/service/open/sendSpecificActionEmail`, {
  actionId
});

export const registerDisposeData = (data) => axios.post(`${constants.utilityHost}/recovery/disposeData/register`, data, {
  headers: headersProvider(),
});

export const getUserData = (data) => axios.post(`${constants.utilityHost}/recovery/userRole/get`, data, {
  headers: headersProvider(),
});

export const registerUserAccess = (data) => axios.post(`${constants.utilityHost}/recovery/userRole/register`, data, {
  headers: headersProvider(),
});

export const getApproverData = (data) => axios.post(`${constants.utilityHost}/recovery/dispose/approver/list`, data, {
  headers: headersProvider(),
});

export const toggleActionAddInvoice = (data) => axios.post(`${constants.utilityHost}/recovery/action/toggleActionAddInvoice`, data, {
  headers: headersProvider(),
});

export const getDashboardData = (data) => axios.post(`${constants.utilityHost}/dashboard/initial-data`, data, {
  headers: headersProvider(),
});

export const getActivityData = (data) => axios.post(`${constants.utilityHost}/recovery/activity/get`, data, {
  headers: headersProvider(),
});

export const getDashboardAgeingData = (data) => axios.post(`${constants.utilityHost}/dashboard/ageing-data`, data, {
  headers: headersProvider(),
});

export const getDashboardBuData = (data) => axios.post(`${constants.utilityHost}/dashboard/bu-data`, data, {
  headers: headersProvider(),
});

export const getDashboardStatusData = (data) => axios.post(`${constants.utilityHost}/dashboard/status-data`, data, {
  headers: headersProvider(),
});

// export const fetchAgreementData = (data) => axios.post(`https://api.truecounsel.in/agreements/SignedagreementList`, data); //DEV
export const fetchAgreementData = (data) => axios.post(`https://demobackend.truecounsel.in/agreements/SignedagreementList`, data); //DEMO

export const closeRecovery = (data) => axios.post(`${constants.utilityHost}/recovery/close`, data, {
  headers: headersProvider(),
});

export const sendToHigherAuthority = (data) => axios.post(`${constants.utilityHost}/recovery/sendToHigherAuthority`, data, {
  headers: headersProvider(),
});

export const toggleInvoiceForNotice = (data) => axios.post(`${constants.utilityHost}/recovery/noticeTemplate/toggleActionAddInvoice`, data, {
  headers: headersProvider(),
});

export const getRecoveryDashboardTable = (data) => axios.post(`${constants.utilityHost}/dashboard/table-data`, data, {
  headers: headersProvider(),
});

export const getSpecifcActionData = (data) => axios.post(`${constants.utilityHost}/recovery/action/getSpecific`, data, {
  headers: headersProvider(),
});

export const resetPasswordAPI = (data) => axios.post(`${constants.utilityHost}/recovery/resetpassword`, data, {
  headers: headersProvider(),
});

export const updatePasswordApi = (data) => axios.post(`${constants.utilityHost}/recovery/updatepassword`, data, {
  headers: headersProvider(),
});

export const registerWordTemplate = (data) => axios.post(`${constants.utilityHost}/word/template/register`, data, {
  headers: headersProvider()
});

export const getWordTemplateList = (data) => axios.post(`${constants.utilityHost}/word/template/list`, data, {
  headers: headersProvider()
})

export const registerEmailTemplate = (data) => axios.post(`${constants.utilityHost}/email/template/register`, data, {
  headers: headersProvider()
});

export const updateEmailTemplate = (data) => axios.post(`${constants.utilityHost}/email/template/update`, data, {
  headers: headersProvider()
});

export const getWordTemplateListAPI = (data) => axios.post(`${constants.utilityHost}/email/template/list`, data, {
  headers: headersProvider()
});

export const saveEmailTemplate = (data) => axios.post(`${constants.utilityHost}/email/template/save`, data, {
  headers: headersProvider()
});

export const getInboxList = (data) => axios.post(`${constants.utilityHost}/inbox/list`, data, {
  headers: headersProvider()
});