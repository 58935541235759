import { ActionIcon } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Refresh } from 'tabler-icons-react';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { getInboxList } from '../../utilities/apis/recovery';
import { formatDate, extractFirstTwoLinesOfHtml } from '../../utilities/utilities';

const GmailInbox = () => {
  const [uiConfig, setUiConfig] = useState({
    hoveredRow: null,
    showContent: false,
    selectedRow: null,
    list: [],
    isApiCalled: true
  });

  const fetchInboxList = async () => {
    const response = await apiWrapWithErrorWithData(getInboxList());
    if (response.success) {
      setUiConfig({ ...uiConfig, list: response.list, isApiCalled: false });
    }
  };
  useEffect(() => { 
    if(uiConfig.isApiCalled){
      fetchInboxList();
    }
   }, [uiConfig.isApiCalled]);

  return (
    <>
      <div className="flex flex-col pt-8 pb-14 pl-6 pr-16">
        <div
          className="font-normal text-[20px] leading-[1.55] text-black flex gap-4"
          style={{ color: "#46BDE1" }}>
          Gmail Inbox
          <ActionIcon
            title='Refresh'
            onClick={() => setUiConfig({ ...uiConfig, showContent: false, selectedRow: null, isApiCalled: true })}
            color='gray'
            radius='xl'
            className='cursor-pointer ml-auto'
          >
            <Refresh size={20} />
          </ActionIcon>
        </div>
        {uiConfig.showContent ? (
          <div className="max-w-4xl mx-auto bg-white border border-gray-300 shadow-md rounded-lg p-6 mt-10">
            <div className="text-2xl font-semibold text-yellow-600">{uiConfig.selectedRow.subject}</div>
            <div className='flex mt-5'>
              <div>
                <h2 className="text-[1.5rem] font-semibold text-gray-800 m-0">{uiConfig.selectedRow.from}</h2>
                <div className="text-gray-600">
                  To {uiConfig.selectedRow.to}
                </div>
              </div>
              <div className="text-gray-500 mt-1 mb-4 ml-auto">{formatDate(uiConfig.selectedRow.createdAt)}</div>
            </div>
            <div className="mt-5" dangerouslySetInnerHTML={{ __html: uiConfig.selectedRow.body }} />
          </div>
        ) : (
          <table className="w-full border-collapse mt-8">
            <tbody>
              {uiConfig.list.map((email) => (
                <tr
                  key={email.id}
                  className="pb-[0.5rem] pt-[0.5rem] shadow-inner flex relative cursor-pointer flex items-center text-black text-[0.85rem]"
                  onMouseEnter={() => setUiConfig({ ...uiConfig, hoveredRow: email.id })}
                  onMouseLeave={() => setUiConfig({ ...uiConfig, hoveredRow: null })}
                  style={
                    uiConfig.hoveredRow === email.id
                      ? {
                        boxShadow: 'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)',
                        zIndex: 2,
                      }
                      : {}
                  }
                  onClick={() => setUiConfig({ ...uiConfig, showContent: true, selectedRow: email })}
                >
                  <td className="font-semibold w-[10rem] ml-2 mr-4">
                    <div className="truncate">
                      {email.from}
                    </div>
                  </td>
                  <td className="flex max-w-[50rem]">
                    <div className="truncate">
                      <span className="font-semibold">{email.subject}</span> - {extractFirstTwoLinesOfHtml(email.body)}
                    </div>
                  </td>
                  <td className="ml-auto mr-3">{formatDate(email.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

      </div>
    </>
  );
};

export default GmailInbox;