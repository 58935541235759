import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Modal,
    Select,
    Textarea,
    TextInput,
    Button,
    MultiSelect,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import useMultiFileUpload from "../../hooks/useMultiFileUpload";
import NewDocumentUploader from "../../components/NewDocumentUploader/NewDocumentUploader";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
    registerDisposeData,
    getApproverData,
    closeRecovery,
} from "../../utilities/apis/recovery";
import { formatNumberWithCommas } from "../../utilities/utilities";

function DisposeComponent({ formState, setFormState, errors }) {
    const multiUploadArgs = useMultiFileUpload({});
    const navigate = useNavigate();
    formState.files = multiUploadArgs.finalFiles;
    const [dropDownData, setDropDownData] = useState({
        approver: [],
    });
    const setApproverData = async () => {
        const response = await apiWrapWithErrorWithData(
            getApproverData({ recoveryId: formState.recoveryId })
        );
        if (response.success) {
            setDropDownData((prevState) => ({
                ...prevState,
                approver: response.data.map((item) => ({
                    value: item.email,
                    label: item.name,
                })),
            }));
            setFormState((prevState) => ({
                ...prevState,
                approvers: response.data.map((item) => item.email),
            }));
        }
    };
    const validateForm = () => {
        let errors = {};
        if (!formState.selectedOption) {
            errors.selectedOption = "Please select an option";
        }
        if (
            formState.selectedOption === "Settlement" &&
            !formState.settlementAmount
        ) {
            errors.settlementAmount = "Please enter settlement amount";
        }
        if (
            formState.selectedOption === "Settlement" &&
            !formState.settlementDate
        ) {
            errors.settlementDate = "Please select settlement date";
        }
        if (!formState.reason && formState.selectedOption !== "Fully Recovered") {
            errors.reason = "Please enter reason";
        }
        if (
            formState.approvers.length === 0 &&
            formState.selectedOption !== "Fully Recovered"
        ) {
            errors.approvers = "Please select approvers";
        }
        if (
            formState.selectedOption === "Fully Recovered" &&
            !formState.paymentStatus
        ) {
            errors.paymentStatus = "Please select payment status";
        }
        if (
            formState.selectedOption === "Fully Recovered" &&
            !formState.closingDate
        ) {
            errors.closingDate = "Please select closing date";
        }
        setFormState((prevState) => ({
            ...prevState,
            errors,
        }));
        return Object.keys(errors).length === 0;
    };
    const handleFormSubmit = async () => {
        if (!validateForm()) return;
        const payload = {
            recoveryId: formState.recoveryId,
            selectedOption: formState.selectedOption,
            settlementAmount: formState.settlementAmount,
            settlementDate: formState.settlementDate,
            reason: formState.reason,
            approvers: formState.approvers,
            files: formState.files,
            paymentStatus: formState.paymentStatus,
            closingDate: formState.closingDate,
        };
        let response = await apiWrapWithErrorWithData(registerDisposeData(payload));
        if (response.success) {
            setFormState((prevState) => ({
                ...prevState,
                isModalOpen: false,
                reason: "",
                recoveryId: null,
                selectedOption: "",
                settlementAmount: null,
                settlementDate: null,
                paymentStatus: null,
                closingDate: null,
                approvers: [],
                files: [],
                errors: {},
            }));
            showNotification({
                title: "Success",
                message: response.message,
                color: "teal",
            });
        } else {
            showNotification({
                title: "Error",
                message: response.message,
                color: "red",
            });
        }
    };
    const handleCloseRecovery = async () => {
        const response = await apiWrapWithErrorWithData(
            closeRecovery({ disposeId: formState.id })
        );
        if (response.success) {
            showNotification({
                title: "Success",
                message: "Recovery closed successfully",
                color: "teal",
            });
            navigate("/app/recovery");
        }
    };
    useEffect(() => {
        if (formState.isModalOpen) {
            setApproverData();
        }
    }, [formState.isModalOpen]);

    return (
        <>
            <Modal
                title="finalize debt resolution"
                overflow="inside"
                opened={formState.isModalOpen}
                closeOnClickOutside={false}
                onClose={() => {
                    setFormState((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                        isSubDispose: false,
                        id: null,
                        reason: "",
                        recoveryId: null,
                        selectedOption: "",
                        totalAmount: null,
                        settlementAmount: null,
                        settlementDate: null,
                        paymentStatus: null,
                        closingDate: null,
                        approvers: [],
                        files: [],
                        errors: {},
                    }));
                }}
                size="calc(60vw)"
            >
                <div className="grid grid-cols-3 gap-5">
                    <Select
                        required
                        placeholder="select a option"
                        label="select a option"
                        data={[
                            "Write off",
                            "Settlement",
                            "Case Creation",
                            "Fully Recovered",
                        ]}
                        value={formState.selectedOption}
                        onChange={(value) => {
                            setFormState((prevState) => ({
                                ...prevState,
                                selectedOption: value,
                                reason: "",
                                settlementAmount: null,
                                settlementDate: null,
                                paymentStatus: null,
                                closingDate: null,
                                files: [],
                                errors: {},
                            }));
                        }}
                        error={errors.selectedOption}
                    />
                    <MultiSelect
                        className="min-w-[43%]"
                        required
                        label="approvers"
                        placeholder="select approvers"
                        data={dropDownData.approver}
                        value={formState.approvers}
                        onChange={(value) =>
                            setFormState((prevState) => ({
                                ...prevState,
                                approvers: value,
                            }))
                        }
                        error={errors.approvers}
                    />
                    <TextInput
                        label="total amount"
                        value={formState.totalAmount}
                        disabled
                    />
                </div>
                {formState.selectedOption === "Write off" && (
                    <div className="m-5">
                        <Textarea
                            required
                            minRows={6}
                            label="reason for writeOff"
                            placeholder="explanation for why the debt is being written off"
                            value={formState.reason}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                setFormState((prevState) => ({
                                    ...prevState,
                                    reason: newValue,
                                }));
                            }}
                            error={errors.reason}
                        />
                    </div>
                )}
                {formState.selectedOption === "Settlement" && (
                    <div className="m-5">
                        <div className="grid grid-cols-3 gap-5">
                            <div>
                                <div className="grid grid-cols-1">
                                    <TextInput
                                        required
                                        label="settlement amount"
                                        placeholder="mention settlement amount"
                                        type="text"
                                        value={formatNumberWithCommas(formState.settlementAmount)}
                                        onChange={(event) => {
                                            const newValue = event.currentTarget.value.replace(
                                                /,/g,
                                                ""
                                            );
                                            setFormState((prevState) => ({
                                                ...prevState,
                                                settlementAmount: newValue,
                                            }));
                                        }}
                                        error={errors.settlementAmount}
                                    />
                                    <DatePicker
                                        className="mt-2"
                                        required
                                        minDate={new Date()}
                                        openDropdownOnClear={true}
                                        label="settlement date"
                                        placeholder="select settlement date"
                                        value={formState.settlementDate}
                                        onChange={(date) =>
                                            setFormState((prevState) => ({
                                                ...prevState,
                                                settlementDate: date,
                                            }))
                                        }
                                        error={errors.settlementDate}
                                    />
                                </div>
                            </div>
                            <Textarea
                                className="col-span-2"
                                required
                                minRows={4}
                                label="summary"
                                placeholder="summary of the recovery"
                                value={formState.reason}
                                onChange={(event) => {
                                    const newValue = event.currentTarget.value;
                                    setFormState((prevState) => ({
                                        ...prevState,
                                        reason: newValue,
                                    }));
                                }}
                                error={errors.reason}
                            />
                        </div>

                        <div className="mt-5">
                            <NewDocumentUploader multiUploadArgs={multiUploadArgs} />
                        </div>
                    </div>
                )}
                {formState.selectedOption === "Case Creation" && (
                    <div className="m-5">
                        <Textarea
                            required
                            minRows={6}
                            label="case description"
                            placeholder="mention case description"
                            value={formState.reason}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                setFormState((prevState) => ({
                                    ...prevState,
                                    reason: newValue,
                                }));
                            }}
                            error={errors.reason}
                        />
                    </div>
                )}
                {formState.selectedOption === "Fully Recovered" && (
                    <div className="m-5 grid grid-cols-2 gap-5">
                        <Select
                            required
                            placeholder="select payment status"
                            label="payment status"
                            data={["Recieved", "Pending"]}
                            value={formState.paymentStatus}
                            onChange={(value) =>
                                setFormState((prevState) => ({
                                    ...prevState,
                                    paymentStatus: value,
                                }))
                            }
                            error={errors.paymentStatus}
                        />
                        <DatePicker
                            required
                            label="closing date"
                            placeholder="select closing date"
                            value={formState.closingDate}
                            openDropdownOnClear={true}
                            onChange={(date) =>
                                setFormState((prevState) => ({
                                    ...prevState,
                                    closingDate: date,
                                }))
                            }
                            error={errors.closingDate}
                        />
                    </div>
                )}
                <div className="flex justify-center mt-5">
                    {formState.isSubDispose ? (
                        <Button color="red" onClick={handleCloseRecovery}>
                            close recovery
                        </Button>
                    ) : (
                        <Button color="green" onClick={handleFormSubmit}>
                            send for approval
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
}

export default DisposeComponent;
