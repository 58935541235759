import React, { useState, useRef, useEffect } from "react";
import { ActionIcon } from "@mantine/core";
import {
    X,
    Underline,
    Bold,
    Italic,
    Strikethrough,
    AlignLeft,
    AlignCenter,
    AlignRight,
    ListNumbers,
    List,
    IndentDecrease,
    IndentIncrease,
    Blockquote,
    Eraser,
    ArrowBackUp,
    ArrowForwardUp,
} from "tabler-icons-react";

const OutlookEditor = ({ formState, setFormState }) => {
    const editorRef = useRef(null);
    const [uiConfig, setUiConfig] = useState({
        showToolBar: false,
        toolbarPosition: { top: 0, left: 0 },
    });

    useEffect(() => {
        if (formState.isModalOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [formState.isModalOpen]);

    const handleMouseUp = () => {
        const selection = window.getSelection();
        if (selection && selection.toString().trim().length > 0) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            setUiConfig({
                ...uiConfig,
                showToolBar: true,
                toolbarPosition: { top: rect.top - 130, left: rect.left - 170 },
            });
        } else {
            setUiConfig({ ...uiConfig, showToolBar: false });
        }
    };

    const applyCommand = (command) => {
        document.execCommand(command, false, null);
    };

    return (
        <>
            {formState.isModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex z-[200] shadow-sm justify-center items-center"
                    style={{
                        fontFamily:
                            '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                    }}
                >
                    <div className="bg-white relative rounded-sm shadow-lg h-[40rem] w-[75rem] mr-10 b-0">
                        <div className="flex items-center gap-2 px-2 min-h-[3.5rem]">
                            <button className="bg-[#1B61D1] text-white text-[1rem] py-1.5 px-5 rounded-sm border-none hover:bg-[#0F548C] hover:shadow-xl hover:scale-105 transition-transform duration-200">
                                Save
                            </button>
                            <div className="ml-2">
                                From: <span className="">{formState.fromEmail}</span>
                            </div>
                            <div className="flex ml-auto">
                                <ActionIcon
                                    title="close"
                                    onClick={() =>
                                        setFormState({ ...formState, isModalOpen: false })
                                    }
                                >
                                    <X size={20} />
                                </ActionIcon>
                            </div>
                        </div>
                        <hr className="m-0 bg-[#D8D8D8] h-[2px] opacity-50 border-none" />
                        <div className="px-2 min-h-[3.3rem] flex items-center justify-between">
                            <div
                                data-is-focusable="true"
                                tabIndex="0"
                                className="inline-block relative h-[1.7rem] leading-[30px] m-[4px_10px_1px_1px] min-w-[52px] px-[4px] text-center font-semibold text-neutral-dark rounded-sm border border-solid border-gray-400 bg-neutral-primary-surface cursor-pointer hover:bg-gray-200 hover:shadow-xl hover:scale-105 transition-transform duration-200"
                                role="button"
                                aria-roledescription="button, opens Add recipients dialog"
                            >
                                To
                            </div>
                            <div className="grid grid-cols-2 gap-1">
                                <ActionIcon>Cc</ActionIcon>
                                <ActionIcon>Bcc</ActionIcon>
                            </div>
                        </div>
                        <hr className="m-0 ml-[4.4rem] bg-[#D8D8D8] h-[2px] opacity-50 border-none" />
                        <div className="px-2 flex items-center justify-between min-h-[3rem]">
                            <div className="font-normal text-[0.9rem]">Add a subject</div>
                            <div className="font-normal text-[0.9rem]">
                                Draft saved at 5:40 PM
                            </div>
                        </div>
                        <hr className="m-0 bg-[#D8D8D8] h-[2px] opacity-50 border-none" />
                        <div
                            id="editable-div"
                            className="focus:outline-none focus-visible:outline-none rounded-md p-2 mt-2 overflow-y-auto"
                            role="textbox"
                            aria-label="Message Body"
                            contentEditable={true}
                            spellCheck={false}
                            style={{
                                maxHeight: "25rem",
                                textAlign: "left",
                                direction: "ltr",
                            }}
                            ref={editorRef}
                            onMouseUp={handleMouseUp}
                        ></div>
                        {uiConfig.showToolBar && (
                            <div
                                className="absolute flex gap-1 bg-white border border-gray-300 rounded shadow-lg p-2"
                                style={{
                                    top: uiConfig.toolbarPosition.top,
                                    left: uiConfig.toolbarPosition.left,
                                    boxShadow:
                                        "0 -2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <ActionIcon onClick={() => applyCommand("undo")}>
                                    <ArrowBackUp color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("redo")}>
                                    <ArrowForwardUp color="#474A49" size={20} />
                                </ActionIcon>
                                |
                                <ActionIcon onClick={() => applyCommand("bold")}>
                                    <Bold size={20} color="black" />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("italic")}>
                                    <Italic size={20} color="black" />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("underline")}>
                                    <Underline size={20} color="black" />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("strikeThrough")}>
                                    <Strikethrough color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("justifyLeft")}>
                                    <AlignLeft color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("justifyCenter")}>
                                    <AlignCenter color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("justifyRight")}>
                                    <AlignRight color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("insertOrderedList")}>
                                    <ListNumbers color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("insertUnorderedList")}>
                                    <List color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("outdent")}>
                                    <IndentDecrease color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("indent")}>
                                    <IndentIncrease color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon
                                    onClick={() => applyCommand("formatBlock", "blockquote")}
                                >
                                    <Blockquote color="#474A49" size={20} />
                                </ActionIcon>
                                <ActionIcon onClick={() => applyCommand("removeFormat")}>
                                    <Eraser color="#474A49" size={20} />
                                </ActionIcon>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default OutlookEditor;
