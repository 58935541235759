import axios from 'axios';
import {
  headersProvider
} from '../apiHelpers';
import constants from '../constants';

export const usersLogin = ({
  accessToken,
}) => axios.post(`${constants.utilityHost}/users/login`, {
  accessToken,
}, {
  headers: headersProvider(),
  noTrailingSlash: true,
});

export const userDetails = () => axios.get(`${constants.utilityHost}/users/details/`, {
  headers: headersProvider(),
});

export const workspaceUsers = () => axios.get(`${constants.utilityHost}/users/workspace-users-list/`, {
  headers: headersProvider(),
});

export const changeUserRole = (roleId) => axios.get(`${constants.utilityHost}/users/loginAs/${roleId}`, {
  headers: headersProvider(),
});

export const getSelfNotifications = ({
  take,
  page,
  sortByOptions,
  filterOptions,
}) => axios.post(`${constants.utilityHost}/notifications/self`, {
  page,
  take,
  sortByOptions,
  filterOptions,
}, {
  headers: headersProvider(),
});

export const getDashboardTAT = ({
  month
}) => axios.get(`${constants.utilityHost}/dashboard/sla/${month}`, {
  headers: headersProvider(),
});

export const getSelfNotificationCount = () => axios.get(`${constants.utilityHost}/notifications/self/count`, {
  headers: headersProvider(),
});

export const setNotificationSeen = (data) => axios.post(`${constants.utilityHost}/notifications/seen`, data, {
  headers: headersProvider(),
});