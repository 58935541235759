import React, { useState, useEffect } from "react";
import { Table, Badge } from "@mantine/core";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getActivityData } from "../../utilities/apis/recovery";
import { formatDate } from "../../utilities/utilities";

const ActivityComponent = ({ recoveryId }) => {
  const [activityData, setActivityData] = useState({
    actionActivity: [],
    finalApproval: [],
    approvalActivity: [],
  });
  const fetchDetails = async () => {
    const response = await apiWrapWithErrorWithData(
      getActivityData({ recoveryId })
    );
    if (response.success) {
      setActivityData((prevState) => ({
        ...prevState,
        actionActivity: response.data.actionAudit,
        finalApproval: response.data.approvalLogs,
        approvalActivity: response.data.approvalActivity,
      }));
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [recoveryId]);

  return (
    <>
      <div className="m-10">
        <div className="font-normal text-[20px] leading-[1.55] text-black mb-5" style={{ color: '#46BDE1' }}>
          action activity
        </div>
        <Table striped highlightOnHover={true}>
          <thead>
            <tr>
              <th>reference no.</th>
              <th>activity</th>
              <th>remark</th>
              <th>modified by</th>
              <th>date</th>
            </tr>
          </thead>
          <tbody>
            {activityData.actionActivity.map((row) => (
              <tr key={row.actionId}>
                <td>{`action-${row.actionId}`}</td>
                {!row.isPaused ? (
                  <td>
                    <Badge>unpaused</Badge>
                  </td>
                ) : (
                  <td>
                    <Badge color="red">paused</Badge>
                  </td>
                )}
                <td>{row.remark}</td>
                <td>{row.creatorName}</td>
                <td>{formatDate(row.createdAt)}</td>
              </tr>
            ))}
            {activityData.actionActivity.length === 0 && (
              <tr>
                <td colSpan={5} className="text-center">
                  no data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="m-10">
        <div className="font-normal text-[20px] leading-[1.55] text-black mb-5" style={{ color: '#46BDE1' }}>
          approval activity
        </div>
        <Table striped highlightOnHover={true}>
          <thead>
            <tr>
              <th>sr no.</th>
              <th>name</th>
              <th>role</th>
              <th>comment</th>
              <th>date</th>
            </tr>
          </thead>
          <tbody>
            {activityData.approvalActivity.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.createdBy.name}</td>
                {row.isNMI ? (
                  <td>
                    <Badge>Editor</Badge>
                  </td>
                ) : (
                  <td>
                    <Badge>Initiator</Badge>
                  </td>
                )}
                <td>{row.comment}</td>
                <td>{formatDate(row.createdAt)}</td>
              </tr>
            ))}
            {activityData.approvalActivity.length === 0 && (
              <tr>
                <td colSpan={5} className="text-center">
                  no data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="m-10">
        <div className="font-normal text-[20px] leading-[1.55] text-black mb-5" style={{ color: '#46BDE1' }}>
          final approval
        </div>
        <Table striped highlightOnHover={true}>
          <thead>
            <tr>
              <th>sr no.</th>
              <th>email</th>
              <th>status</th>
              <th>date</th>
            </tr>
          </thead>
          <tbody>
            {activityData.finalApproval.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.emailID}</td>
                {row.isApproved ? (
                  <td>
                    <Badge color="green">Aprroved</Badge>
                  </td>
                ) : (
                  <td>
                    <Badge color="red">Rejected</Badge>
                  </td>
                )}
                <td>{formatDate(row.createdAt)}</td>
              </tr>
            ))}
            {activityData.finalApproval.length === 0 && (
              <tr>
                <td colSpan={5} className="text-center">
                  no data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ActivityComponent;
