// Source: https://ui.mantine.dev/component/navbar-nested

import React, { useEffect, useState } from 'react';
import {
  Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, createStyles, Badge
} from '@mantine/core';
import {
  ChevronLeft, ChevronRight,
} from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { apiWrapWithErrorWithData } from '../utilities/apiHelpers';
import { getSelfNotificationCount } from '../utilities/apis/users';

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'block',
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    borderLeft: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: 'transform 200ms ease',
  },
}));

export function LinksGroup({
  icon: Icon, label, initiallyOpened, links, link,
}) {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === 'ltr' ? ChevronRight : ChevronLeft;
  const navigate = useNavigate();
  const items = (hasLinks ? links : []).map((itemLink) => (
    <Text
      component="a"
      className={classes.link}
      href={itemLink.link}
      key={itemLink.label}
      onClick={(event) => {
        event.preventDefault();
        navigate(itemLink.link);
      }}
    >
      {itemLink.label}
    </Text>
  ));
  const fetchNotificationCount = () => {
    apiWrapWithErrorWithData(getSelfNotificationCount()).then((res) => {
      if (res.success) {
        setNotificationsCount(res.count);
      }
    });
  };
  useEffect(() => {
    fetchNotificationCount();
  }, []);

  return (
    <>
      <UnstyledButton
        onClick={() => {
          if (link && link.length) {
            navigate(link);
          }
          setOpened((o) => !o);
          fetchNotificationCount();
        }}
        className={classes.control}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon size={18} color='#49A0EB' />
              <Box ml="md">
                {label === 'notifications' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {label}
                  </Box>
                ) : (
                  label
                )}
              </Box>
            </Box>
            {label === 'notifications' && (
              <Badge ml="sm" color="#46BDE1" variant="filled" className='ml-[5.6rem]'>
                {notificationsCount}
              </Badge>
            )}
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              //
              style={{
                transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}
