import React, { useState, useEffect } from 'react';
import {
  Text,
  Select,
  TextInput,
  Modal,
  Button,
  Table,
  Pagination,
  ActionIcon,
  Checkbox
} from "@mantine/core";
import { Send, Search } from "tabler-icons-react";
import { fetchAgreementData } from "../../utilities/apis/recovery";
import { formatDate } from "../../utilities/utilities";


const AgreementListModal = ({ agreementData, setAgreementData, setFormState, setRegisteredNameDropDown }) => {
  const [uiConfig, setUiConfig] = useState({
    page: 1,
    totalCount: 116,
    apiCall: true,
    filters: {
      titleOfAgreement: null,
      typeOfAgreement: null,
      OR: [
        { secondParty: { path: "$.name", string_contains: "" } },
        {
          secondParty: {
            path: "$.indi_name",
            string_contains: "",
          },
        },
      ],
    },
    dropDown: {
      category: [
        "NDA",
        "Service Agreement",
      ],
      title: [
        "truecounsel NDA",
        "tc services",
      ]
    }
  });

  const getAgreementData = async () => {
    const { page, filters } = uiConfig;
    const { titleOfAgreement, typeOfAgreement, OR } = filters;

    const payload = {
      page,
      filters: {
        OR,
        ...(titleOfAgreement && { titleOfAgreement }),
        ...(typeOfAgreement && { typeOfAgreement }),
      },
    };

    const response = await fetchAgreementData(payload);
    if (response.status === 200) {
      setAgreementData({
        ...agreementData,
        list: response.data.agreementsList
      });
      setUiConfig({
        ...uiConfig,
        totalCount: response.data.count,
        apiCall: false,
      });
    }
  };

  useEffect(() => {
    if (uiConfig.apiCall) {
      getAgreementData();
      setUiConfig((prevState) => ({ ...prevState, apiCall: false }));
    }
  }, [uiConfig.apiCall, agreementData.isModalOpen]);

  const handleAgreementSelect = (agreement) => {
    if (agreement.secondParty.type === "Entity") {
      setFormState((prevState) => ({
        ...prevState,
        type: "entity",
        entityType: agreement.secondParty.entityType || null,
        registeredName: agreement.secondParty.name || null,
        representativeName: agreement.secondParty.signatory || null,
        representativeEmail: agreement.secondParty.email || null,
        registeredAddress: agreement.secondParty.address || null,
      }));
      setRegisteredNameDropDown((prevData) => [...prevData, agreement.secondParty.name]);
    } else {
      setFormState((prevState) => ({
        ...prevState,
        type: "individual",
        individualName: agreement.secondParty.indi_name || null,
        emailID: agreement.secondParty.indi_email || null,
        residenceAddress: agreement.secondParty.indi_address || null,
        phoneNo: agreement.secondParty.indi_phone || null,
      }));
    }
    setAgreementData({
      ...agreementData,
      isModalOpen: false,
      selectedAgreement: agreement.id,
    });
    console.log("Agreement Selected", agreement);
  };

  const handleClearClick = () => {
    setUiConfig({
      ...uiConfig,
      page: 1,
      apiCall: true,
      filters: {
        titleOfAgreement: null,
        typeOfAgreement: null,
        OR: [
          { secondParty: { path: "$.name", string_contains: "" } },
          {
            secondParty: {
              path: "$.indi_name",
              string_contains: "",
            },
          },
        ],
      },
    });
  }

  return (
    <Modal
      overflow="inside"
      opened={agreementData.isModalOpen}
      onClose={() =>
        setAgreementData({ ...agreementData, isModalOpen: false })
      }
      size="calc(70vw)"
      title="Agreement List"
    >
      <br />
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row-sm">
            <div className="flex items-center">
              <TextInput
                className="mx-2 min-w-[225px]"
                placeholder="search second party"
                icon={<Search size={14} />}
                value={uiConfig.filters.OR[0].secondParty.string_contains}
                onChange={(e) =>
                  setUiConfig({
                    ...uiConfig,
                    filters: {
                      ...uiConfig.filters,
                      OR: [
                        { secondParty: { path: "$.name", string_contains: e.target.value } },
                        { secondParty: { path: "$.indi_name", string_contains: e.target.value } },
                      ],
                    },
                  })
                }
              />
              <Select
                className="mx-2 min-w-[225px]"
                placeholder="select agreement type"
                data={uiConfig.dropDown.category}
                value={uiConfig.filters.typeOfAgreement}
                onChange={(value) =>
                  setUiConfig({
                    ...uiConfig,
                    filters: {
                      ...uiConfig.filters,
                      typeOfAgreement: value,
                    },
                  })
                }
              />
              <Select
                className="mx-2 min-w-[225px]"
                placeholder="select agreement title"
                data={uiConfig.dropDown.title}
                value={uiConfig.filters.titleOfAgreement}
                onChange={(value) =>
                  setUiConfig({
                    ...uiConfig,
                    filters: {
                      ...uiConfig.filters,
                      titleOfAgreement: value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <Button
        className="mx-2"
        color="green"
        onClick={() => setUiConfig((prevState) => ({ ...prevState, page: 1, apiCall: true }))}
      >
        Search
      </Button>
      <Button className="mx-2" color="red" onClick={handleClearClick}>
        Clear
      </Button>
      <br />
      <br />
      <Table striped highlightOnHover={true} className="">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Reference no.</th>
            <th>Title</th>
            <th>Type</th>
            <th>Location</th>
            <th>Second Party</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {agreementData.list.length === 0 ? (
            <tr>
              <td colSpan={9} className="text-center">
                <Text size={22} className="m-8">
                  No data found
                </Text>
              </td>
            </tr>
          ) : agreementData.list.map((agreement) => (
            <tr key={agreement.id}>
              <td>
                <Checkbox
                  title="Select Agreement"
                  id={agreement.id}
                  onChange={() => handleAgreementSelect(agreement)}
                />
                {/* <ActionIcon
                  title="Select Agreement"
                  onClick={() => handleAgreementSelect(agreement)}
                >
                  <Send color="green" />
                </ActionIcon> */}
              </td>
              <td>{agreement.id}</td>
              <td>{agreement.titleOfAgreement}</td>
              <td>{agreement.typeOfAgreement}</td>
              <td>{agreement.location}</td>
              <td>{agreement.secondParty.name}</td>
              <td>{formatDate(agreement.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {uiConfig.totalCount ? (
        <div className="flex flex-row justify-center">
          <Pagination
            page={uiConfig.page}
            onChange={(page) => setUiConfig((prevState) => ({ ...prevState, page: page, apiCall: true }))}
            total={Math.ceil(uiConfig.totalCount / 10)}
          />
        </div>
      ) : null}
    </Modal>
  );
};

export default AgreementListModal;