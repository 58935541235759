import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import { Select, MultiSelect } from "@mantine/core";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getUserData } from "../../utilities/apis/recovery";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";

function UserRolePage({ recoveryId, formState, setFormState }) {
    const [dropDownData, setDropDownData] = useState({
        initiator: [],
        editor: [],
        approver: [],
    });
    const currentUser = useSelector(selectUserData) || {};
    const setUserData = async () => {
        const response = await apiWrapWithErrorWithData(
            getUserData({ recoveryId })
        );
        if (response.success) {
            const mapData = (data) => data.map(item => ({
                value: item.id,
                label: item.name
            }));

            setDropDownData({
                initiator: mapData(response.data.initiator),
                editor: mapData(response.data.editor),
                approver: mapData(response.data.approver)
            });

            if (formState.initiator.length === 0 || formState.editor.length === 0) {
                if (currentUser.loginAs.name === "Initiator") {
                    setFormState({
                        ...formState,
                        initiator: [currentUser.id],
                    });
                } else if (currentUser.loginAs.name === "Editor") {
                    setFormState({
                        ...formState,
                        editor: [currentUser.id],
                    });
                }
            }
        }
    };

    useEffect(() => {
        setUserData();
    }, []);

    return (
        <div className="w-full flex flex-col my-4">
            <div className="grid grid-cols-3 gap-4 m-8">
                <MultiSelect
                    label="initiator"
                    placeholder="select initiators"
                    value={formState.initiator}
                    data={dropDownData.initiator}
                    onChange={(value) => setFormState({ ...formState, initiator: value })}
                    error={formState.errors.initiator}
                />
                <MultiSelect
                    label="editor"
                    placeholder="select editors"
                    value={formState.editor}
                    data={dropDownData.editor}
                    onChange={(value) => setFormState({ ...formState, editor: value })}
                    error={formState.errors.editor}
                />
                <MultiSelect
                    label="approver"
                    placeholder="select approvers"
                    value={formState.approver}
                    data={dropDownData.approver}
                    onChange={(value) => setFormState({ ...formState, approver: value })}
                    error={formState.errors.approver}
                />
            </div>
        </div>
    );
}

export default UserRolePage;
