import { Button, Text, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { assetsPrefix } from "../../utilities/utilities";
import { At } from "tabler-icons-react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { resetPasswordAPI } from "../../utilities/apis/recovery";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const [disable, setDisable] = useState(false);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const handleResetPassword = async (e) => {
        setDisable(true);
        e.preventDefault();
        const response = await apiWrapWithErrorWithData(
            resetPasswordAPI({ email })
        );
        setDisable(false);
        showNotification({
            title: "reset password",
            message: response.message,
            color: response.success ? "green" : "red",
        });
        if (response.success) {
            navigate("/app/login");
        }
    };
    return (
        <div>
            <div className="w-screen h-screen bg-white">
                <div className="flex w-full h-full justify-center items-center">
                    <div className="flex flex-col w-80 justify-center">
                        <img
                            style={{ width: "12rem" }}
                            src={`${assetsPrefix}/images/truecounsel.png`}
                            alt="TrueCounsel"
                            className="flex justify-center"
                        />
                        <TextInput
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="my-2"
                            label="Email"
                            placeholder="Enter email"
                            icon={<At size={14} />}
                        />

                        <Button
                            onClick={(e) => {
                                handleResetPassword(e);
                            }}
                            className="my-2"
                            disabled={disable}
                        >
                            reset password
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
