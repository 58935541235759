import React, { useState, useEffect } from "react";
import { Table, ActionIcon, Modal, Button, Switch } from "@mantine/core";
import { Edit, FileText } from "tabler-icons-react";
import { formatDate } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
    getNoticeTemplateList,
    saveNoticeTemplate,
    lockNoticeTemplate,
    toggleInvoiceForNotice
} from "../../utilities/apis/recovery";
import Editor from "../Editor";

function NoticeComponent({ recoveryId, haveActionRight = true }) {
    const [templateData, setTemplateData] = useState({
        noticeList: [],
        isModalOpen: false,
        content: {},
        templateId: null,
        actionId: null,
        executionDate: null,
        isNoticeLocked: false,
        isInvoiceAddedToNotice: false,
    });
    const fetchNoticeTemplates = async () => {
        const response = await apiWrapWithErrorWithData(
            getNoticeTemplateList({ recoveryId })
        );
        if (response.success) {
            console.log("response: Notice Component ---------------------------->>>> ", response);
            setTemplateData((prevState) => ({
                ...prevState,
                noticeList: response.noticeList,
            }));
        }
    };
    const handleNoticeTemplateSave = async () => {
        const response = await apiWrapWithErrorWithData(
            saveNoticeTemplate({
                actionId: templateData.actionId,
                noticeTemplateContent: templateData.content,
            })
        );
        if (response.success) {
            // const { url } = await getPreviewUrl(templateData.content);
            // if (url && url.length) {
            //     window.open(url, '_blank').focus();
            // } else {
            //     console.log('No url found', url);
            // }
            fetchNoticeTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: false,
                content: {},
                templateId: null,
                actionId: null,
                executionDate: null,
                isNoticeLocked: false,
            }));
        }
    };
    const handleNoticeTemplateLock = async () => {
        const response = await apiWrapWithErrorWithData(
            lockNoticeTemplate({
                actionId: templateData.actionId,
                noticeTemplateContent: templateData.content,
                isNoticeLocked: true,
            })
        );
        if (response.success) {
            fetchNoticeTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: false,
                content: {},
                templateId: null,
                actionId: null,
                executionDate: null,
                isNoticeLocked: false,
            }));
            if (response?.success && response?.data.documentLink) {
                window.open(response.data.documentLink, "_blank").focus();
            }
        }
    };
    const handleNoticeTemplateUnlock = async () => {
        const response = await apiWrapWithErrorWithData(
            lockNoticeTemplate({
                actionId: templateData.actionId,
                noticeTemplateContent: templateData.content,
                isNoticeLocked: false,
            })
        );
        if (response.success) {
            fetchNoticeTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: false,
                content: {},
                templateId: null,
                actionId: null,
                executionDate: null,
                isNoticeLocked: false,
            }));
        }
    };
    const dateDiffCal = (data) => {
        const executionDate = new Date(data);
        const currentDate = new Date();
        const differenceInTime = currentDate - executionDate;
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays !== 1 ? true : false;
    };
    const handleNoticeEdit = (row) => {
        if (row.isNoticeLocked && row.wordUrl) {
            window.open(row.wordUrl, "_blank").focus();
        } else {
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: true,
                content: row.noticeTemplateContent,
                templateId: row.noticeTemplateId,
                actionId: row.id,
                executionDate: row.executionDate,
                isNoticeLocked: row.isNoticeLocked,
            }));
        }
    }
    const handleAddingInvoiceData = async () => {
        const response = await apiWrapWithErrorWithData(
            toggleInvoiceForNotice({
                actionId: templateData.actionId,
                isInvoiceAddedToNotice: !templateData.isInvoiceAddedToNotice,
            })
        );
        if (response.success) {
            fetchNoticeTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isInvoiceAddedToNotice: !templateData.isInvoiceAddedToNotice,
            }));
        }
    }
    useEffect(() => {
        fetchNoticeTemplates();
    }, [recoveryId]);
    return (
        <>
            <Modal
                title="Edit Notice Template"
                overflow="inside"
                opened={templateData.isModalOpen}
                onClose={() => {
                    setTemplateData((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                        content: {},
                        templateId: null,
                        actionId: null,
                        executionDate: null,
                        isNoticeLocked: false,
                    }));
                }}
                size="calc(80vw)"
            >
                <div className="flex justify-between p-2">
                    {!templateData.isNoticeLocked ? (
                        <>
                            <Switch
                                label={templateData.isInvoiceAddedToNotice ? "Hide Invoice Data" : "Show Invoice Data"}
                                checked={templateData.isInvoiceAddedToNotice}
                                onChange={handleAddingInvoiceData}
                            />
                            <div>
                                <Button onClick={handleNoticeTemplateSave}>Save</Button>
                                <Button className="mx-2" onClick={handleNoticeTemplateLock}>
                                    Lock and open in word
                                </Button>
                            </div>
                        </>
                    ) : dateDiffCal(templateData.executionDate) ? (
                        <Button onClick={handleNoticeTemplateUnlock}>Unlock</Button>
                    ) : (
                        <div className="font-bold text-[15px] leading-[1.55] text-red-500 text-center">
                            Time limit exceeded to unlock the email template.
                        </div>
                    )}
                </div>
                <Editor
                    locked={templateData.isNoticeLocked}
                    content={templateData.content}
                    templateId={templateData.templateId}
                    onContentChange={(content) => {
                        setTemplateData((prevState) => ({
                            ...prevState,
                            content,
                        }));
                    }}
                />
            </Modal>
            <div className="m-10">
                {templateData.noticeList.length > 0 ? (
                    <Table striped highlightOnHover={true}>
                        <thead>
                            <tr>
                                <td>reference no.</td>
                                <td>execution date</td>
                                <td>name</td>
                                <td>&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody>
                            {templateData.noticeList.map((row) => (
                                <tr key={row.id}>
                                    <td>{`action-${row.id}`}</td>
                                    <td>{formatDate(row.executionDate)}</td>
                                    <td>{row.templateName}</td>
                                    <td>
                                        <div className="grid grid-cols-1">
                                            <ActionIcon
                                                title="Edit"
                                                icon="edit"
                                                onClick={() => handleNoticeEdit(row)}
                                            >
                                                {row.isNoticeLocked ? <FileText color="green" /> : <Edit color="red" />}
                                            </ActionIcon>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div className="flex justify-center items-center h-50">
                        <div className="flex flex-col items-center">
                            <h4 className="text-gray-500">no notice templates found</h4>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default NoticeComponent;
