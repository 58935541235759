import React, { useState } from 'react';
import { Text, TextInput, ActionIcon } from '@mantine/core';
import { Search, Plus, Folder, File, Eye, Trash, Edit } from 'tabler-icons-react';

const Document = () => {
  const [path, setPath] = useState([]);

  const handleClick = (category) => {
    if (!path.includes(category)) {
      setPath([...path, category]);
    }
  };

  return (
    <>
      <div className="m-5">
        <div className="flex items-center justify-between">
          <div className="flex">
            <Text size='xl' weight={100} className="ml-2 hover:text-blue-400 hover:underline cursor-pointer" onClick={() => setPath([])}>home</Text>
            {path.map((p, index) => (
              <React.Fragment key={index}>
                <span className="mx-2">
                  <Text size='xl' weight={500} className="ml-2">{'>'}</Text>
                </span>
                <Text size='xl' weight={100} className="ml-2 hover:text-blue-400 hover:underline cursor-pointer" onClick={() => setPath(path.slice(0, index + 1))}>{p}</Text>
              </React.Fragment>
            ))}
          </div>
          <div className="flex">
            <TextInput
              className="mx-2"
              placeholder="Search text"
              icon={<Search size={14} />}
            />&nbsp;
            <ActionIcon color='blue' className='m-1' title="create new folder">
              <Plus size={30} />
            </ActionIcon>
          </div>
        </div>
      </div>
      <div className="m-5">
        <div className="flex items-center justify-between hover:bg-gray-200 p-2 cursor-pointer" onClick={() => handleClick('marketing')}>
          <div className='flex'>
            <ActionIcon color='blue' className=''>
              {/* File icon should be used in case of file display */}
              <Folder />
            </ActionIcon>
            <Text size='xl' weight={100} className="ml-2">marketing</Text>
            {/* <Text size='xl' weight={100} className="ml-2">sevice agreement visible to all</Text> */}
          </div>
          {/* Condition should be maintained when it is a  file and should be visible on hover*/}
          {/* <div className='flex gap-3 mr-5'>
            <ActionIcon className=''>
              <Eye />
            </ActionIcon>
            <ActionIcon color='blue' className=''>
              <Edit />
            </ActionIcon>
            <ActionIcon color='red' className=''>
              <Trash />
            </ActionIcon>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Document;