import React, { useEffect, useState } from "react";
import {
    Pagination,
    Select,
    Skeleton,
    Text,
    Modal,
    Button,
    Table,
    ActionIcon,
    TextInput,
    Badge,
} from "@mantine/core";
import { Plus, FileText, ClipboardCheck, Eye } from "tabler-icons-react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
    registerWordTemplate,
    getWordTemplateList,
} from "../../utilities/apis/recovery";
import { formatDate } from "../../utilities/utilities";

const NoticeList = ({ isViewOnly = false, useTemplateFunction }) => {
    const [uiConfig, setUiConfig] = useState({
        isModalOpen: false,
        isPreviewModalOpen: false,
        etag: null,
        selectedRow: {},
        filter: {
            category: "All",
        },
        apiCall: true,
    });
    const [formState, setFormState] = useState({
        id: null,
        name: "",
        category: "",
        templateList: [],
        errors: {},
    });
    const [dropDown, setDropDown] = useState({
        category: ["All"],
    });

    const validateForm = () => {
        const errors = {};
        if (!formState.name) {
            errors.name = "name is required";
        }
        if (!formState.category) {
            errors.category = "category is required";
        }
        setFormState((prevState) => ({
            ...prevState,
            errors,
        }));
        return Object.keys(errors).length === 0;
    };

    const handleFormSubmit = async () => {
        if (validateForm()) {
            console.log(formState);
            const payload = {
                name: formState.name,
                category: formState.category,
            };
            const response = await apiWrapWithErrorWithData(
                registerWordTemplate(payload)
            );
            if (response.success) {
                setUiConfig((prevState) => ({
                    ...prevState,
                    isModalOpen: false,
                    apiCall: true,
                }));
            }
        }
    };

    const fetchWordTemplateList = async () => {
        const payload = {
            category: uiConfig.filter.category,
        };
        const response = await apiWrapWithErrorWithData(
            getWordTemplateList(payload)
        );
        if (response.success) {
            setFormState((prevState) => ({
                ...prevState,
                templateList: response.data.list,
            }));
            if (response.data.dropDown.category.length > 0) {
                setDropDown((prevState) => ({
                    ...prevState,
                    category: [...dropDown.category, ...response.data.dropDown.category],
                }));
            }
        }
    };

    const handleEdit = (row) => {
        if (row.webURL) {
            window.open(row.webURL, "_blank").focus();
        } else {
            console.log("No url found", row);
        }
    };

    const useTemplateHandler = useTemplateFunction
        ? () => {
            useTemplateFunction(uiConfig.selectedRow);
        }
        : null;

    useEffect(() => {
        if (uiConfig.apiCall) {
            fetchWordTemplateList();
            setUiConfig((prevState) => ({
                ...prevState,
                apiCall: false,
            }));
        }
    }, [uiConfig.apiCall]);

    return (
        <>
            <Modal
                overflow="inside"
                title="add new template"
                size="calc(40vw)"
                opened={uiConfig.isModalOpen}
                onClose={() => {
                    setUiConfig((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                    }));
                    setFormState((prevState) => ({
                        ...prevState,
                        id: null,
                        name: "",
                        category: "",
                        errors: {},
                    }));
                }}
                closeOnClickOutside={false}
            >
                <TextInput
                    required
                    label="name"
                    type="text"
                    placeholder="enter template category"
                    value={formState.name}
                    onChange={(event) => {
                        const newValue = event.currentTarget.value;
                        setFormState((prevState) => ({
                            ...prevState,
                            name: newValue,
                        }));
                    }}
                    error={formState.errors.name}
                />
                <Select
                    required
                    searchable
                    creatable
                    label="category"
                    placeholder="select category"
                    value={formState.category}
                    data={[...new Set(dropDown.category)]}
                    getCreateLabel={(value) => `+ add ${value}`}
                    onCreate={(value) => {
                        setFormState((prevState) => ({
                            ...prevState,
                            category: value,
                        }));
                        setDropDown((prevState) => ({
                            ...prevState,
                            category: [...dropDown.category, value],
                        }));
                    }}
                    onChange={(value) => {
                        setFormState((prevState) => ({
                            ...prevState,
                            category: value,
                        }));
                    }}
                    error={formState.errors.category}
                />
                <div className="flex justify-center mt-5">
                    <Button color="green" onClick={handleFormSubmit}>
                        save
                    </Button>
                </div>
            </Modal>
            <Modal
                overflow="inside"
                title="MS word template"
                size="calc(55vw)"
                opened={uiConfig.isPreviewModalOpen}
                onClose={() => {
                    setUiConfig((prevState) => ({
                        ...prevState,
                        isPreviewModalOpen: false,
                        etag: null,
                    }));
                }}
                closeOnClickOutside={false}
            >
                <div className="m-5 flex justify-end items-center">
                    <Button onClick={() => useTemplateHandler()}>
                        use this template
                    </Button>
                </div>
                <div className="m-5 flex justify-center">
                    <iframe
                        src={`https://truecounsel-my.sharepoint.com/personal/rajeshtc_truecounsel_onmicrosoft_com/_layouts/15/Doc.aspx?sourcedoc=${encodeURIComponent(
                            uiConfig.selectedRow.etag
                        )}&action=embedview`}
                        width="98%"
                        height="550px"
                        title="MS Word Document"
                        className="border-1 rounded-lg"
                    />
                </div>
            </Modal>
            <div className="flex flex-col pt-8 pb-14 pl-6 pr-16">
                <div
                    className="font-normal text-[20px] leading-[1.55] text-black"
                    style={{ color: "#46BDE1" }}>
                    notice templates
                </div>
                <div className="flex justify-start gap-5 my-4 items-end">
                    <Select
                        className="mt-4 w-80"
                        label="category"
                        value={uiConfig.filter.category}
                        data={[...new Set(dropDown.category)]}
                        onChange={(value) => {
                            setUiConfig((prevState) => ({
                                ...prevState,
                                filter: {
                                    ...prevState.filter,
                                    category: value,
                                },
                                apiCall: true,
                            }));
                        }}
                    />
                    {!isViewOnly && (
                        <ActionIcon
                            color="blue"
                            title="add new template"
                            className="mb-1"
                            onClick={() => setUiConfig({ ...uiConfig, isModalOpen: true })}
                        >
                            <Plus size={30} />
                        </ActionIcon>
                    )}
                </div>
                <div className="mt-10">
                    <Table striped highlightOnHover={true}>
                        <thead>
                            <tr>
                                <th>reference no.</th>
                                <th colSpan={3}>name</th>
                                <th colSpan={2}>category</th>
                                <th>created by</th>
                                <th>date</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formState.templateList.length === 0 ? (
                                <tr>
                                    <td colSpan={9} className="text-center">
                                        <Text size={22} className="m-8">
                                            No data found
                                        </Text>
                                    </td>
                                </tr>
                            ) : (
                                formState.templateList.map((row) => (
                                    <tr key={row.id}>
                                        <td>{`template-${row.id}`}</td>
                                        <td colSpan={3}>{row.name}</td>
                                        <td colSpan={2}>
                                            <Badge>{row.category}</Badge>
                                        </td>
                                        <td>{row.user.name}</td>
                                        <td>{formatDate(row.createdAt)}</td>
                                        <td>
                                            {!isViewOnly ? (
                                                // <div className="grid grid-cols-2 gap-0 mt-1 items-center">
                                                <ActionIcon
                                                    color="blue"
                                                    title="Edit"
                                                    onClick={() => handleEdit(row)}
                                                >
                                                    <FileText />
                                                </ActionIcon>
                                            ) : (
                                                // </div>
                                                <ActionIcon
                                                    color="blue"
                                                    title="View"
                                                    onClick={() =>
                                                        setUiConfig({
                                                            ...uiConfig,
                                                            isPreviewModalOpen: true,
                                                            etag: row.etag,
                                                            selectedRow: row,
                                                        })
                                                    }
                                                >
                                                    <Eye />
                                                </ActionIcon>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default NoticeList;
