import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import {
  Text,
  TextInput,
  Select,
  Button,
  Table,
  ActionIcon,
  Badge,
  Pagination,
  RangeSlider
} from "@mantine/core";
import { Search, SortAscending, Download } from "tabler-icons-react";
import { DateRangePicker } from "@mantine/dates";
import { Eye } from "tabler-icons-react";
import ExcelJS from "exceljs";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { fetchRecoveryDetailApi } from "../../utilities/apis/recovery";
import { formatDate, formatNumberWithCommas } from "../../utilities/utilities";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";

export default function RecoveryListingPage() {
  const currentUser = useSelector(selectUserData) || {};

  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [recoveryList, setRecoveryList] = useState([]);
  const [recoveryData, setRecoveryData] = useState({
    filter: {
      searchText: "",
      nextActionDate: "",
      lastActionDate: "",
      sortType: "DESC",
      orderByColumn: "nextAction",
      minAmount: 0,
      maxAmount: 0,
      roleId: "",
      roleToCompare: "",
      isRecoveryLocker: false,
      apiCall: 0,
    },
    pageNo: 1,
    count: 0,
  });

  const fetchRecoverys = async () => {
    if (currentPath === "/app/recovery/locker") {
      recoveryData.filter.roleId = "";
      recoveryData.filter.roleToCompare = "";
      recoveryData.filter.isRecoveryLocker = true;
    } else {
      recoveryData.filter.roleId = currentUser.id;
      recoveryData.filter.roleToCompare = currentUser.loginAs.name;
      recoveryData.filter.isRecoveryLocker = false;
    }
    const response = await apiWrapWithErrorWithData(
      fetchRecoveryDetailApi({ recoveryData })
    );
    if (response.success) {
      console.log('this is recovery data', response.data);
      if (response.data.length > 0) {
        recoveryData.count = response.data[0].totalCount;
      }
      setRecoveryList(response.data);
    }
  };
  useEffect(() => {
    fetchRecoverys();
  }, [recoveryData.filter.apiCall, currentPath]);

  const handleSearchClick = () => {
    fetchRecoverys();
  };

  const handleClearClick = async () => {
    setRecoveryData({
      ...recoveryData,
      filter: {
        ...recoveryData.filter,
        searchText: "",
        nextActionDate: "",
        lastActionDate: "",
        minAmount: 0,
        maxAmount: 0,
        apiCall: recoveryData.filter.apiCall + 1,
      },
    });
    fetchRecoverys();
  };

  const handleExcelDownload = async () => {
    const mappedData = recoveryList.map(item => ({
      "Ref no": `recovery-${item.id}`,
      "Amount": `${item.currencyType === "dollar" ? "$" : "₹"} ${item.recoveryAmount}`,
      "Debtor Name": item.name,
      "Next Action": item.nextAction ? `${item.nextAction.actionCount} | ${formatDate(item.nextAction.executionDate)}` : "",
      "Last Action": item.lastAction ? `${item.lastAction.actionCount} | ${formatDate(item.lastAction.executionDate)}` : "",
      "Status": item.nextAction ? item.nextAction.isPaused ? "Paused" : "Active" : "Active",
    }));
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.columns = [
      { header: 'Ref no.', key: 'Ref no', width: 15 },
      { header: 'Amount', key: 'Amount', width: 15 },
      { header: 'Debtor Name', key: 'Debtor Name', width: 15 },
      { header: 'Next Action', key: 'Next Action', width: 15 },
      { header: 'Last Action', key: 'Last Action', width: 15 },
      { header: 'Status', key: 'Status', width: 15 },
    ];
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (colNumber <= 6) {
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFADD8E6' }
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
    mappedData.forEach((data) => {
      const row = worksheet.addRow(data);
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'recoveryList.xlsx');
  };


  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between my-4 mb-8">
          <Text
            className="font-normal text-[20px] leading-[1.55] text-black"
            style={{ color: "#46BDE1" }}>
            recoveries list
          </Text>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row-sm">
            <div className="flex items-center">
              {/* <Select
                className="mx-2 min-w-[225px]"
                placeholder="Select recovery status"
                data={["Created", "Edited", "Reviewed", "Completed"]}
                value={recoveryData.filter.status}
                onChange={(value) => {
                  setRecoveryData({
                    ...recoveryData,
                    filter: {
                      ...recoveryData.filter,
                      status: value,
                    },
                  });
                }}
              /> */}
              <TextInput
                className="mx-2 min-w-[225px]"
                placeholder="Search Party Name"
                icon={<Search size={14} />}
                value={recoveryData.filter.searchText}
                onChange={(e) => {
                  setRecoveryData({
                    ...recoveryData,
                    filter: {
                      ...recoveryData.filter,
                      searchText: e.currentTarget.value,
                    },
                  });
                }}
              />
              {/* <TextInput
                className="mx-2 min-w-[225px]"
                placeholder="Less than amount"
                type="number"
                value={recoveryData.filter.minAmount}
                onChange={(e) => {
                  setRecoveryData({
                    ...recoveryData,
                    filter: {
                      ...recoveryData.filter,
                      minAmount: e.currentTarget.value,
                    },
                  });
                }}
              />
              <TextInput
                className="mx-2 min-w-[225px]"
                placeholder="Greater than amount"
                type="number"
                value={recoveryData.filter.maxAmount}
                onChange={(e) => {
                  setRecoveryData({
                    ...recoveryData,
                    filter: {
                      ...recoveryData.filter,
                      maxAmount: e.currentTarget.value,
                    },
                  });
                }}
              /> */}
              <DateRangePicker
                className="mx-2 min-w-[255px]"
                style={{ minWidth: "255px" }}
                placeholder="select next action date"
                value={recoveryData.filter.nextActionDate}
                onChange={(value) => {
                  setRecoveryData({
                    ...recoveryData,
                    filter: {
                      ...recoveryData.filter,
                      nextActionDate: value,
                    },
                  });
                }}
              />
              <DateRangePicker
                className="mx-2 min-w-[255px]"
                style={{ minWidth: "255px" }}
                placeholder="select last action date"
                value={recoveryData.filter.lastActionDate}
                onChange={(value) => {
                  setRecoveryData({
                    ...recoveryData,
                    filter: {
                      ...recoveryData.filter,
                      lastActionDate: value,
                    },
                  });
                }}
              />
              <RangeSlider
                className="mx-2 min-w-[225px] mr-4"
                labelAlwaysOn
                min={0}
                max={1000000}
                step={100}
                value={[recoveryData.filter.minAmount, recoveryData.filter.maxAmount]}
                onChange={(value) => {
                  console.log('this is value', value);
                  setRecoveryData({
                    ...recoveryData,
                    filter: {
                      ...recoveryData.filter,
                      minAmount: value[0],
                      maxAmount: value[1],
                    },
                  });
                }}
                marks={[
                  { value: 0, label: '0' },
                  { value: 250000, label: '2.5L' },
                  { value: 500000, label: '5L' },
                  { value: 750000, label: '7.5L' },
                  { value: 1000000, label: '10L' },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <Button className="mx-2" color="green" onClick={handleSearchClick}>
        search
      </Button>
      <Button className="mx-2" color="red" onClick={handleClearClick}>
        clear
      </Button>
      <br />
      <br />
      <div className="flex items-center justify-end">
        sort:
        <Select
          placeholder="Sort by"
          className="mx-2"
          value={recoveryData.filter.orderByColumn}
          onChange={(value) => {
            setRecoveryData({
              ...recoveryData,
              filter: {
                ...recoveryData.filter,
                orderByColumn: value,
              },
            });
            fetchRecoverys();
          }}
          data={[
            { label: "reference no.", value: "r.id" },
            { label: "amount", value: "d.recoveryAmount" },
            { label: "name", value: "name" },
            { label: "last action", value: "lastAction" },
            { label: "next action", value: "nextAction" },
          ]}
        />
        <ActionIcon
          onClick={() => {
            setRecoveryData((prevState) => ({
              ...prevState,
              filter: {
                ...prevState.filter,
                sortType: prevState.filter.sortType === "ASC" ? "DESC" : "ASC",
              },
            }));
            fetchRecoverys();
          }}
          color="blue"
          className={recoveryData.filter.sortType === "ASC" ? "" : "rotate-180"}
        >
          <SortAscending />
        </ActionIcon>
        <ActionIcon
          className="ml-5"
          title="Excel Download"
          onClick={handleExcelDownload}
        >
          <Download color="green" />
        </ActionIcon>
      </div>
      <div className="flex flex-col">
        {recoveryList.length > 0 ? (
          <Table striped highlightOnHover={true} className="mt-10">
            <thead>
              <tr>
                <td>reference no.</td>
                <td>amount</td>
                <td>name</td>
                <td>next action</td>
                <td>last action</td>
                <td>status</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {recoveryList.map((recovery) => (
                <tr key={recovery.id}>
                  <td>{`recovery-${recovery.id}`}</td>
                  <td className="text-right pl-3">{`${recovery.currencyType === "dollar" ? "$" : "₹"} ${formatNumberWithCommas(recovery.recoveryAmount)}`}</td>
                  <td>{recovery.name}</td>
                  {recovery.nextAction ? (
                    <td>{`${recovery.nextAction.actionCount} | ${formatDate(recovery.nextAction.executionDate)}`}</td>
                  ) : (
                    <td>&nbsp;</td>
                  )}
                  {recovery.lastAction ? (
                    <td>{`${recovery.lastAction.actionCount} | ${formatDate(
                      recovery.lastAction.executionDate
                    )}`}</td>
                  ) : (
                    <td>&nbsp;</td>
                  )}
                  <td>
                    <Badge
                      color={
                        recovery.nextAction
                          ? recovery.nextAction.isPaused
                            ? "red"
                            : "blue"
                          : "blue"
                      }
                    >
                      {recovery.nextAction
                        ? recovery.nextAction.isPaused
                          ? "Paused"
                          : "Active"
                        : "Active"}
                    </Badge>
                  </td>
                  <td>
                    <ActionIcon
                      color="blue"
                      title="View"
                      onClick={() => navigate(`/app/recovery/${recovery.id}`)}
                    >
                      <Eye />
                    </ActionIcon>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="flex justify-center items-center h-50">
            <div className="flex flex-col items-center">
              <Text>No recoverys found</Text>
            </div>
          </div>
        )}
      </div>
      {recoveryData.count > 10 ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={recoveryData.pageNo}
            onChange={(page) => {
              recoveryData.pageNo = page;
              fetchRecoverys();
            }}
            total={Math.ceil(recoveryData.count / 10)}
          />
        </div>
      ) : recoveryData.count ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination page={1} total={1} />
        </div>
      ) : null}
    </>
  );
}
