/* eslint-disable */
import React, { useState } from 'react';
import { Avatar, Menu, Text, Input, CloseButton, Select } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import styles from './AppHeader.module.css';
import { assetsPrefix } from '../../utilities/utilities';
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { selectUserData } from '../../redux/selectors';
import { DoorExit, Lock, Exchange } from 'tabler-icons-react';
import { deleteCookie, getCookiesSession, setCookiesSession } from '../../cookiesSession';
import { changeUserRole } from "../../utilities/apis/users";
import actions from "../../redux/actions";


const AppHeader = () => {

    const userData = useSelector(selectUserData);
    const [opened, setOpened] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(selectUserData) || {};
    let roles = currentUser.userRole || [];

    const logoutHandler = () => {
        if (getCookiesSession('trueCounselUserData')) {
            deleteCookie('trueCounselUserData');
        }
        window.location = process.env.REACT_APP_LANDING_PAGE_URL;
    };

    const handleRoleChange = async (value) => {
        try {
            const resp = await apiWrapWithErrorWithData(changeUserRole(value));
            // console.log(resp);
            if (resp?.success && resp?.token && resp?.user) {
                setCookiesSession("trueCounselUserData", JSON.stringify(resp), 2);
                dispatch({
                    type: actions.SET_USER_DATA,
                    payload: resp.user,
                });
                navigate(0)
            }
            // Reload the page
        } catch (error) {
            console.error("Error while switching roles:", error);
            // Handle error if needed
        }
    };

    return (
        <div className="flex flex-row justify-between mx-8 my-2">
            <img className={styles.logo} src={`${assetsPrefix}/images/truecounsel.png`} alt="TrueCounsel" />
            <div className='flex'>
                {/* <Input
                    icon={<AiOutlineSearch />}
                    value={searchInput}
                    rightSection={
                        <CloseButton onClick={() => { setSearchInput(''); navigate('/app') }} />
                    }
                    placeholder='Search'
                    className='mx-3'
                    onChange={(e) => {
                        const input = e.target.value;
                        input.length > 2 ? navigate(`/app/search/${input}`) : navigate('/app');
                        setSearchInput(input);
                    }}
                /> */}
                <Select
                    required
                    className="max-w-lg mr-2"
                    placeholder="Select Role to login as"
                    data={roles.map((role) => ({
                        label: role.name,
                        value: role.id,
                        pass: role,
                    }))}
                    // label={currentUser.loginAs.name}
                    value={currentUser.loginAs.id}
                    onChange={(pass) => handleRoleChange(pass)}
                />
                <Menu>
                    <Menu.Target>
                        <div onClick={() => { setOpened(!opened) }} className="flex items-center cursor-pointer">
                            {userData.picture?.length ? <Avatar size="md" src={userData.picture} radius="xl" />
                                : <Avatar size="md" radius="xl">{userData.name[0].toUpperCase()}</Avatar>}
                            <div className="ml-2 flex flex-col">
                                <Text size="md">{userData.name}</Text>
                                <Text size="xs">{userData.email}</Text>
                            </div>
                        </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item onClick={() => window.location.href = 'https://demoapp.truecounsel.in/app/choose_manager'} icon={<Exchange size={14} />}>
                            choose manager&nbsp;&nbsp;
                        </Menu.Item>
                        <Menu.Item onClick={() => navigate('/app/updatePassword')} icon={<Lock size={14} />}>
                            update password&nbsp;&nbsp;
                        </Menu.Item>
                        <Menu.Item onClick={logoutHandler} icon={<DoorExit size={14} />}>Logout</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </div>
        </div>
    );
};

export default AppHeader;
